<template>
    <invoicable-view-template
        :title="$t($route.meta.title)"
        :current-invoicable="currentInvoicable"
        :invoicable-type="invoicableType"
        :loading="loading"
    >
        <template v-if="!isAccountant(currentUserData)" #actions>
            <v-card class="pa-5">
                <v-card-header>
                    <template #title> {{ $t('general.actions') }} </template>
                </v-card-header>
                <v-col cols="12" md="12" class="pa-0">
                    <submit-button
                        v-if="showCreateNewButton"
                        id="createNew"
                        data-action="createNew"
                        color="primary"
                        block
                        class="my-3"
                        @click="createNewInvoicable($route.params.type, currentInvoicable.historical)"
                    >
                        {{ $t('viewInvoicable.creat_new') }}
                    </submit-button>
                    <submit-button
                        v-if="currentInvoicable.draft === false"
                        id="sendeMail"
                        data-action="send-email"
                        color="primary"
                        block
                        class="my-3"
                        @click="sendEmailInvoicable($route.params.type, $route.params.id)"
                    >
                        {{ $t('viewInvoicable.send_email') }}
                    </submit-button>
                    <submit-button
                        id="edit"
                        data-action="edit"
                        color="primary"
                        block
                        class="my-3"
                        @click="
                            navigateToEditInvoicable($route.params.id, $route.params.type, currentInvoicable.historical)
                        "
                    >
                        {{ $t('general.edit') }}
                    </submit-button>
                    <open-messenger-modal-button
                        :polymorphic-document-id="currentInvoicable.id"
                        :document-id="documentId"
                    />
                </v-col>
            </v-card>
        </template>
    </invoicable-view-template>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
import SubmitButton from '@/components/SubmitButton.vue';
import VCardHeader from '@/components/vCardHeader.vue';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { getHtmlRepresentation } from '@/services/invoicable';
import InvoicableViewTemplate from './components/InvoicableViewTemplate.vue';
import OpenMessengerModalButton from '@/views/communication/OpenMessengerModalButton.vue';

export default {
    components: {
        SubmitButton,
        VCardHeader,
        InvoicableViewTemplate,
        OpenMessengerModalButton
    },

    mixins: [formRulesMixin, screenSizeMixin],

    beforeRouteEnter(to, from, next) {
        const params = to.params;
        if (!(params.id && params.type)) {
            return next({
                name: 'dashboard'
            });
        }

        if (!['invoice', 'creditnote', 'quotation'].includes(params.type)) {
            return next({
                name: 'dashboard'
            });
        }

        return next();
    },

    data() {
        return {
            currentInvoicable: null,
            loading: false,
            htmlRepresentation: null
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        invoicableType() {
            return this.$route.params.type;
        },
        showCreateNewButton() {
            return this.$route.query.newlyCreated;
        },
        documentId() {
            return this.currentInvoicable.documentId;
        }
    },

    async created() {
        this.loading = true;
        try {
            this.isCreatingNew = false;

            [this.currentInvoicable, this.htmlRepresentation] = await Promise.all([
                this.getInvoicablelById({
                    id: this.$route.params.id,
                    type: this.$route.params.type
                }),
                getHtmlRepresentation(this.$route.params.id, this.$route.params.type)
            ]);

            if (this.currentInvoicable.historical && !this.currentInvoicable.url) {
                return this.$router.replace({
                    name: `edit-historical-${this.$route.params.type}`,
                    params: {
                        id: this.$route.params.id
                    }
                });
            }
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
            if (e.response && (e.response.status === 404 || e.response.status === 403)) {
                this.stopLoading();
                this.$router.push({ name: 'dashboard' });
            }
        } finally {
            this.loading = false;
        }
    },

    methods: {
        ...mapActions(['stopLoading']),
        ...mapActions('incomes', ['getInvoicablelById']),

        openFile(url) {
            window.open(url);
        },
        navigateToEditInvoicable(id, type, historical) {
            this.$router.push({
                name: historical ? `edit-historical-${type}` : `edit-${type}`,
                params: {
                    id
                }
            });
        },
        createNewInvoicable(type, historical) {
            this.$router.push({
                name: historical ? `add-${type}-historical` : `add-${type}`
            });
        },
        sendEmailInvoicable(type, id) {
            this.$router.push({
                name: `send-${type}-email`,
                params: {
                    id
                }
            });
        }
    }
};
</script>
